import React from 'react';
import './App.css';
import TrackListing from './components/TrackListing';


function App() {

  return (

    <div className="App">
      <TrackListing />
    </div>
  );
}

export default App;
